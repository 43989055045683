<template>
  <div class="network">
    <div class="empty-cont">
      <img src="../assets/images/empty/systemerr.svg" alt="">
      <div class="tips">
        System crashed T_T...
      </div>
    </div>
  </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
.network {
  img {
    width: 200px;
    height: 200px;
  }

  .empty-cont {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 201px;
  }

  .tips {
    margin-top: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: rgba(28, 31, 35, 0.8);
  }
}
</style>
